import React from 'react';
import Layout from '../../components/layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="text-center mx-auto my-5">
        <div className="badge-title">Cookie Policy</div>
        <h2 className="page-title d-block">Cookie Policy</h2>
        <p>Last modified May 30, 2022</p>
      </div>

      <div className="section-main">
        <p>
          This Cookie Policy describes how and why HEXACT use cookies, web beacons, pixels, tags, scripts and other
          similar technologies in the course of our business, including through websites and apps that link to this
          Cookie Policy. For additional information about our privacy practices, please review our Privacy Policy.
        </p>
        <p className="bold">What are cookies?</p>
        <p>
          Cookies are small data files placed on your computer or other internet-enabled device that enable our features
          and functionality. They allow us to record information when you visit or interact with our websites, products,
          services, and our mobile applications (collectively, our “Services”). Other tracking technologies, such as web
          beacons and pixels work similarly to cookies, placing small data files on your device that monitor your
          Website activity.
        </p>
        <p className="bold">How do we use cookies?</p>
        <p>
          We use cookies and other tracking technologies to make our Services easier to use and to better tailor them to
          your interests and needs. We also use these technologies to compile anonymous, aggregated information that
          allow us to better understand our customers and visitors.
        </p>
        <p className="bold">What kinds of cookies and tracking technologies do we use?</p>
        <p className="bold">Persistent and Session Cookies</p>
        <p>
          We use two categories of cookies: Persistent cookies and session cookies. Persistent cookies are cookies that
          help us recognize you. They are stored on your device in between browser sessions, allowing us to remember
          your preferences and actions across multiple sites and on multiple visits. Session cookies expire at the end
          of your browser session, allowing us to link your actions during a particular browsing session.
        </p>
        <p className="bold">Google Analytics</p>
        <p>
          We use first party Google Analytics cookies to track anonymous usage statistics and do not collect any
          personal information that can be used to identify you. This helps us analyse data about website usage and
          improve our Services in order to tailor it to customer needs.
        </p>
        <p className="bold">Third Party Cookies</p>
        <p>
          Third party cookies are cookies that are set by others, enabling the third party’s features or functionalities
          to be provided through the Website you are using. The third party setting these cookies can recognize your
          device both when it visits our Services and when it visits certain other websites or services. The service
          providers of third-party cookies have their own privacy policies, and may use their cookies to target
          advertising to you.
        </p>
        <p className="bold">Why do we use cookies?</p>
        <p>
          We generally use cookies that are necessary or essential to the functioning of our Services, cookies that help
          us improve the performance or customize the functionality of our Services, and cookies that help us serve you
          relevant advertisements. The types of cookies described below may be set by us or by a third party service
          provider assisting us in performing the below functions. Specifically, we use cookies for the following
          reasons:
        </p>
        <p>
          Strictly Necessary: These cookies and tracking technologies let you navigate our Services and use essential
          features. Without these technologies, we are unable to deliver core functionality of our Services, products
          and services to you.
        </p>
        <p>
          Performance: These cookies and tracking technologies help us understand how customers and visitors interact
          with our Services. They provide us with information about areas of our Services visited, time spent on our
          Services and any error messages you receive, allowing us to improve the performance of our Services. They do
          not collect any information that could identify you.
        </p>
        <p>
          Functional: These cookies and tracking technologies are used to provide certain services or to remember
          settings that will improve your visit, but are not essential to your use of our Services. They may include
          personal information that you have disclosed to us.
        </p>
        <p>
          Social Media Cookies: These cookies are set by third parties, including Facebook and other social networks,
          and allow you to share what you’ve been doing on our Services on social media.
        </p>
        <p className="bold">How can I control cookies?</p>
        <p>
          Internet browsers allow you to change your cookie settings via the “options’ or “preferences” menu in your
          browser. Please note that if you set your browser to refuse or block all cookies, certain features or
          functionalities of our Services will be limited or unavailable to you.
        </p>
        <p>
          Some internet browsers include the ability to transmit “do not track” signals. Because no industry standard
          has yet been developed with respect to “do not track,” our Services do not currently process or respond to
          such “do not track” signals.
        </p>
        <p>
          You can opt out of Google Analytics without affecting how you visit our site. For more information on opting
          out of being tracked by Google Analytics across all websites you use, visit this Google page:{' '}
          <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" className="external-link" rel='noopener'>
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </p>
        <p>
          Further information about deleting and blocking cookies can be found at{' '}
          <a href="" target="_blank" className="external-link" rel='noopener'>
            http://www.allaboutcookies.org
          </a>
          .
        </p>

        <p className="bold">How can I get further information?</p>
        <p className="mb-0">
          If you have any questions about our use of cookies or other tracking technologies, please email us at
          privacy@hexact.io
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
